@import "@/assets/scss/style.scss";

.news-cms-wrapper {
    margin: 5rem auto;
    width: 70%;
    max-height: 70vh;
    border: 1px solid rgb(212, 212, 212);

    @media (max-width: 1400px) {
        width: 80%;
        max-height: 80vh;
    }

    @media (max-width: 1100px) {
        width: 90%;
    }

    @media (max-width: 800px) {
        width: 95%;
    }

    .news__top-bar {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0.5rem;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        button,
        span {
            cursor: pointer;
            font-size: 1rem;
        }

        .news-cms__span-wrapper {
            @include flex-center;
            margin-left: 40%;

            @media (max-width: 768px) {
                margin: 0 0 1rem 0;
            }

            .divider {
                margin: 0 0.5rem;
                height: 20px;
                width: 1px;
                background-color: #000;
            }

            .is-active {
                color: #000;
                border-bottom: 1px solid #000;
            }
        }

        .news-cms__button-wrapper {
            margin-left: auto;
            padding-left: 0.5rem;

            @media (max-width: 768px) {
                margin: 0;
            }

            .news__create-button {
                margin: 0 0.5rem;
            }
        }

        button {
            font-size: 1rem;
        }
    }
    .news-cms-content {
        padding: 2rem;

        ::v-deep .ant-table-content {
            overflow-x: auto;
        }

        ::v-deep td {
            word-wrap: normal;
            white-space: nowrap;
        }
    }

    .delete-icon {
        font-size: 20px;
        color: red;
    }
}

.news-modal {
    max-height: 1200px;
    overflow-y: auto;
}

.ant-table-content {
    // overflow-x: auto;
}
